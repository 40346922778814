// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-for-ceos-tsx": () => import("./../../../src/pages/for-ceos.tsx" /* webpackChunkName: "component---src-pages-for-ceos-tsx" */),
  "component---src-pages-for-designers-tsx": () => import("./../../../src/pages/for-designers.tsx" /* webpackChunkName: "component---src-pages-for-designers-tsx" */),
  "component---src-pages-for-developers-tsx": () => import("./../../../src/pages/for-developers.tsx" /* webpackChunkName: "component---src-pages-for-developers-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */),
  "component---src-pages-storybook-tsx": () => import("./../../../src/pages/storybook.tsx" /* webpackChunkName: "component---src-pages-storybook-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

